<template>
    <div
      v-if="
        ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.tesouraria == 'S') &&
          $store.state.fastPermissoes.ativo == 'S' &&
          $store.state.fastPlataformaModulos.modulo_secretaria
      "
    >
      <div class="fd-app-wrap">
        <div class="container">
          <!-- breadcrumb -->
          <nav>
    
            <ul class="fd-app-breadcrumb">
              <li class="fd-app-breadcrumb-item">
                <a
                  href="/minhas-plataformas"
                  class="aluno_font_color"
                  @click.prevent="$router.push('/minhas-plataformas')"
                >Minhas Plataformas</a>
              </li>
              <li class="fd-app-breadcrumb-item">
                <a
                  class="aluno_font_color"
                  href="#"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
                >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
              </li>
              <li class="fd-app-breadcrumb-item">
                <a
                  class="aluno_font_color"
                  href="#"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
                >Modo secretaria</a>              
              </li>
              <li class="fd-app-breadcrumb-item active">
                <a
                  class="aluno_font_color"
                  href="#"
                >Relatórios - Avaliações Alunos</a>
              </li>
            </ul>
          </nav>
  
          <!-- /breadcrumb -->
        </div>
        <div class="fd-mode-adm-content mt-5 container">
          
          <!-- Conteúdo Principal Relatório -->
          <transition name="slide-fade">
            <div v-if="fastNavegacao.exibirRelatorio">
              <section class="fd-app-welcome text-left">
                  <h2><b>Relatório Avaliações Alunos</b></h2>
                  <img
                    class="mb-3"
                    :src="require('@/assets/images/form-effect-01.png')"
                    style="max-height: 16px"
                  >
                </section>
                <div class="d-flex">
                  <div class="align-self-start mr-auto mb-2">
                    <button v-if="this.fastAvaliacoesRel.filtroEscolhido.matriculaAluno"
                      @click="fastAvaliacoesRel.filtroEscolhido.matriculaAluno = '', getAvaliacoesRel()" type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      Matrícula: {{ this.fastAvaliacoesRel.filtroEscolhido.matriculaAluno }} <b-icon icon="x-lg" font-scale="0.7"
                        class="mb-1"></b-icon>
                    </button>
                    <button v-if="this.fastAvaliacoesRel.filtroEscolhido.idTurma"
                      @click="fastAvaliacoesRel.filtroEscolhido.idTurma = '', getAvaliacoesRel()" type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      Turma: <template 
                              v-for="turma in fastAvaliacoesRel.filtros.turmas"
                              :value="turma.id_turma">
                              <span v-if="fastAvaliacoesRel.filtroEscolhido.idTurma == turma.id_turma">{{ turma.descricao_turma }} </span>
                            </template>
                        <b-icon icon="x-lg" font-scale="0.7"
                        class="mb-1"></b-icon>
                    </button>
                    <button v-if="this.fastAvaliacoesRel.filtroEscolhido.idCurso"
                      @click="fastAvaliacoesRel.filtroEscolhido.idCurso = '', getAvaliacoesRel()" type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      Curso: <template 
                              v-for="curso in fastAvaliacoesRel.filtros.cursos"
                              :value="curso.id_secretaria_curso">
                              <span v-if="fastAvaliacoesRel.filtroEscolhido.idCurso == curso.id_secretaria_curso">{{ curso.nome_curso }} </span>
                            </template>
                        <b-icon icon="x-lg" font-scale="0.7"
                        class="mb-1"></b-icon>
                    </button>
                    <button v-if="this.fastAvaliacoesRel.filtroEscolhido.statusAprovado === 'true'"
                      @click="fastAvaliacoesRel.filtroEscolhido.statusAprovado = '', getAvaliacoesRel()" type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      Status: Aprovado <b-icon icon="x-lg" font-scale="0.7"
                        class="mb-1"></b-icon>
                    </button>
                    <button v-if="this.fastAvaliacoesRel.filtroEscolhido.statusAprovado === 'false'"
                      @click="fastAvaliacoesRel.filtroEscolhido.statusAprovado = '', getAvaliacoesRel()" type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      Status: Reprovado <b-icon icon="x-lg" font-scale="0.7"
                        class="mb-1"></b-icon>
                    </button>
                    <button v-if="this.fastAvaliacoesRel.filtroEscolhido.anoMatricula"
                      @click="fastAvaliacoesRel.filtroEscolhido.anoMatricula = '', getAvaliacoesRel()" type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      Ano Matrícula: {{this.fastAvaliacoesRel.filtroEscolhido.anoMatricula}} <b-icon icon="x-lg" font-scale="0.7"
                        class="mb-1"></b-icon>
                    </button>
                    <button v-if="this.fastAvaliacoesRel.filtroEscolhido.nomeAluno"
                      @click="fastAvaliacoesRel.filtroEscolhido.nomeAluno = '', getAvaliacoesRel()" type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      Aluno: {{ this.fastAvaliacoesRel.filtroEscolhido.nomeAluno }} <b-icon icon="x-lg" font-scale="0.7"
                        class="mb-1"></b-icon>
                    </button>
                  </div>
                </div>
              <section>
                <div class="row">
                  <form class="form col-sm-12 col-md-12 col-lg-4 pl-3 pr-3">
                    <label class="text-secondary mb-0 mt-2"><small>Plataforma:</small></label>
                    <select
                      class="form-control form-control-sm"
                      v-model="fastAvaliacoesRel.filtroEscolhido.idPlataforma"
                      @change="getTurmas(), getCursos()" >
                      <option>Todas</option>
                      <option v-for="(plataforma, index) in fastAvaliacoesRel.filtros.plataformas" :key="index"
                        :value="plataforma.id_plataforma">
                        {{ plataforma.nome_plataforma }}
                      </option>
                    </select>
                    <label class="text-secondary mb-0 mt-2"><small>Matrícula:</small></label>
                    <input
                    type="text"
                    v-model="fastAvaliacoesRel.filtroEscolhido.matriculaAluno"
                    class="form-control form-control-sm"
                    />
                    <label class="text-secondary mb-0 mt-2"><small>Status:</small></label>
                    <select
                    :class="fastAvaliacoesRel.filtroEscolhido.statusAprovado ? 'form-control form-control-sm border border-primary text-primary' : 'form-control form-control-sm'"
                    v-model="fastAvaliacoesRel.filtroEscolhido.statusAprovado">
                    <option value="">Todos</option>
                    <option value="true">
                      Aprovado
                    </option>
                    <option value="false">
                      Reprovado
                    </option>
                  </select>
                  </form>
                  <form class="form col-sm-12 col-md-12 col-lg-4 pl-3 pr-4">
                    <label class="text-secondary mb-0 mt-2"><small>Aluno:</small></label>
                    <input
                    type="text"
                    v-model="fastAvaliacoesRel.filtroEscolhido.nomeAluno"
                    class="form-control form-control-sm"
                    />
                    <label class="text-secondary mb-0 mt-2"><small>Turma:</small></label>
                    <select
                      :class="fastAvaliacoesRel.filtroEscolhido.idTurma ? 'form-control form-control-sm border border-primary text-primary' : 'form-control form-control-sm'"
                      v-model="fastAvaliacoesRel.filtroEscolhido.idTurma" >
                      <option value ="">Todas</option>
                      <option v-for="(turma, index) in fastAvaliacoesRel.filtros.turmas" :key="index"
                        :value="turma.id_turma">
                        {{ turma.descricao_turma }}
                      </option>
                    </select>
                    <label class="text-secondary mb-0 mt-2"><small>Curso:</small></label>
                    <select
                      :class="fastAvaliacoesRel.filtroEscolhido.idCurso ? 'form-control form-control-sm border border-primary text-primary' : 'form-control form-control-sm'"
                      v-model="fastAvaliacoesRel.filtroEscolhido.idCurso" >
                      <option value ="">Todos</option>
                      <option v-for="(curso, index) in fastAvaliacoesRel.filtros.cursos" :key="index"
                        :value="curso.id_secretaria_curso">
                        {{ curso.nome_curso }}
                      </option>
                    </select>

                  </form>
                  <form class="form col-sm-12 col-md-12 col-lg-4 pl-3 pr-3">
                    <label class="text-primary mb-1 mt-2 row">
                      <small class="text-primary ml-0 pl-3 col-8">Data Matrícula:</small>
                      <small
                      class="col-4 text-right mr-0 pr-3"
                      role="button"
                      v-if="fastAvaliacoesRel.filtroEscolhido.dataMatriculaStart || fastAvaliacoesRel.filtroEscolhido.dataMatriculaEnd" 
                      @click="fastAvaliacoesRel.filtroEscolhido.dataMatriculaStart = '', fastAvaliacoesRel.filtroEscolhido.dataMatriculaEnd = ''"
                      >Limpar <b-icon icon="x-circle" scale="1.1" variant="danger"></b-icon></small>
                    </label>
                    <div class="row pl-3 pr-3">
                      <b-form-datepicker 
                      :class="validaDataFiltro(fastAvaliacoesRel.filtroEscolhido.dataMatriculaStart, fastAvaliacoesRel.filtroEscolhido.dataMatriculaEnd) ? 'col-6 date-picker-filtro' : 'col-6 date-picker-filtro border-danger text-red'"
                      @input="validaDataFiltro(fastAvaliacoesRel.filtroEscolhido.dataMatriculaStart, fastAvaliacoesRel.filtroEscolhido.dataMatriculaEnd, true)"
                      v-model="fastAvaliacoesRel.filtroEscolhido.dataMatriculaStart"
                      locale="pt-BR"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                      label-no-date-selected="Selecionar data inicial"
                      size="sm"
                      placeholder="Selecionar data inicial"
                      label-help="Use o cursor para selecionar"
                      ></b-form-datepicker>
                      <b-form-datepicker 
                      :class="validaDataFiltro(fastAvaliacoesRel.filtroEscolhido.dataMatriculaStart, fastAvaliacoesRel.filtroEscolhido.dataMatriculaEnd) ? 'col-6 date-picker-filtro' : 'col-6 date-picker-filtro border-danger text-red'"
                      @input="validaDataFiltro(fastAvaliacoesRel.filtroEscolhido.dataMatriculaStart, fastAvaliacoesRel.filtroEscolhido.dataMatriculaEnd, true)"
                      v-model="fastAvaliacoesRel.filtroEscolhido.dataMatriculaEnd"
                      locale="pt-BR"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                      label-no-date-selected="Selecionar data final"
                      size="sm"
                      placeholder="Selecionar data final"
                      label-help="Use o cursor para selecionar"
                      ></b-form-datepicker>
                    </div>
                    <label class="text-primary mb-1 mt-2 row">
                      <small class="text-primary ml-0 pl-3 col-8">Data Realização:</small>
                      <small
                      class="col-4 text-right mr-0 pr-3"
                      role="button"
                      v-if="fastAvaliacoesRel.filtroEscolhido.dataRealizacaoStart || fastAvaliacoesRel.filtroEscolhido.dataRealizacaoEnd" 
                      @click="fastAvaliacoesRel.filtroEscolhido.dataRealizacaoStart = '', fastAvaliacoesRel.filtroEscolhido.dataRealizacaoEnd = ''"
                      >Limpar <b-icon icon="x-circle" scale="1.1" variant="danger"></b-icon></small>
                    </label>
                    <div class="row pl-3 pr-3">
                      <b-form-datepicker 
                      :class="validaDataFiltro(fastAvaliacoesRel.filtroEscolhido.dataRealizacaoStart, fastAvaliacoesRel.filtroEscolhido.dataRealizacaoEnd) ? 'col-6 date-picker-filtro' : 'col-6 date-picker-filtro border-danger text-red'"
                      @input="validaDataFiltro(fastAvaliacoesRel.filtroEscolhido.dataRealizacaoStart, fastAvaliacoesRel.filtroEscolhido.dataRealizacaoEnd, true)"
                      v-model="fastAvaliacoesRel.filtroEscolhido.dataRealizacaoStart"
                      locale="pt-BR"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                      label-no-date-selected="Selecionar data inicial"
                      size="sm"
                      placeholder="Selecionar data inicial"
                      label-help="Use o cursor para selecionar"
                      ></b-form-datepicker>
                      <b-form-datepicker 
                      :class="validaDataFiltro(fastAvaliacoesRel.filtroEscolhido.dataRealizacaoStart, fastAvaliacoesRel.filtroEscolhido.dataRealizacaoEnd) ? 'col-6 date-picker-filtro' : 'col-6 date-picker-filtro border-danger text-red'"
                      @input="validaDataFiltro(fastAvaliacoesRel.filtroEscolhido.dataRealizacaoStart, fastAvaliacoesRel.filtroEscolhido.dataRealizacaoEnd, true)"
                      v-model="fastAvaliacoesRel.filtroEscolhido.dataRealizacaoEnd"
                      locale="pt-BR"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                      label-no-date-selected="Selecionar data final"
                      size="sm"
                      placeholder="Selecionar data final"
                      label-help="Use o cursor para selecionar"
                      ></b-form-datepicker>
                    </div>
                    <label class="text-secondary mb-0 mt-2"><small>Ano Matrícula:</small></label>
                    <b-form-select 
                    size="sm"
                    v-model="fastAvaliacoesRel.filtroEscolhido.anoMatricula"
                    @change="fastAvaliacoesRel.filtroEscolhido.dataMatriculaStart = '', fastAvaliacoesRel.filtroEscolhido.dataMatriculaEnd = ''">
                        <option value="">Todos</option>
                        <option v-for="ano in fastAvaliacoesRel.filtros.anos" :value="ano">{{ ano }}</option>
                    </b-form-select>
                
                  </form>
                </div>
                <div class="row">
                  <div class="text-left col-6 mt-4 mb-3">
                    <b-button @click="exportaPlanilha()" variant="custom-outline-primary btn-sm text-capitalize rounded">Exportar</b-button>                       
                  </div>
                  <div class="text-right col-6 mt-4 mb-3">
                    <b-button @click="limpaFiltros()" variant="custom-outline-primary btn-sm text-capitalize rounded mr-3">Limpar Filtros</b-button>
                    <b-button @click="getAvaliacoesRel()" variant="custom-primary btn-sm text-capitalize rounded">Filtrar</b-button>                       
                  </div>
                </div>
              </section>

                <div class="row">
                  <div class="col-12 table-responsive">
                    <!--tabela oculta sem paginação para exportar-->
                    <table id="relatorio" class="table d-none">
                      <thead>
                        <tr>
                          <th><small>Ano Matrícula</small></th>
                          <th><small>Data Matrícula</small></th>
                          <th><small>Data Realização</small></th>
                          <th><small>Aluno</small></th>
                          <th><small>Nota</small></th>
                          <th><small>Resultado</small></th>
                          <th><small>Gabarito</small></th>
                          <th><small>Idade</small></th>
                          <th><small>Matrícula</small></th>
                          <th><small>Valor Pago</small></th>
                          <th><small>Valor em Aberto</small></th>
                          <th><small>Avaliação</small></th>
                          <th><small>Turma</small></th>
                          <th><small>Curso</small></th>
                        </tr>
                      </thead>
                  <tbody>
                    <tr
                      v-for="(e, index) in fastAvaliacoesRel.filtro"
                      :key="index"
                    >
                      <td><small>{{ e.ano_matricula }}</small></td>
                      <td><small>{{ formataDataT(e.data_matricula) }}</small></td>
                      <td>     
                        <small v-if="e.DataRealizacaoFim">{{ e.DataRealizacaoFim }}</small>
                        <small v-else>Sem data</small>                               
                      </td>
                      <td><small>{{ e.NomeAluno }}</small></td>
                      <td>
                        <small v-if="e.Nota"> {{ e.Nota }} </small>  
                        <small v-else>-</small>                               
                      </td>
                      <td><small> {{ e.Resultado }} </small></td>
                      <td>
                        <small v-if="e.Gabarito"> {{ e.Gabarito }} </small>  
                        <small v-else>-</small>                             
                      </td>
                      <td><small>{{ e.Idade }}</small></td>
                      <td><small>{{ e.id_matricula }}</small></td>
                      <td>
                        <small v-if="e.ValorPago">R$ {{ formataPreco(e.ValorPago) }}</small>
                        <small v-else>R$ 0,00</small>
                      </td>
                      <td>
                        <small v-if="e.ValorEmAberto">R$ {{ formataPreco(e.ValorEmAberto) }}</small> 
                        <small v-else>R$ 0,00</small>                                
                      </td>
                      <td><small>{{ e.Avaliacao }} </small></td>
                      <td><small>{{ e.Turma }} </small></td>
                      <td><small>{{ e.Curso }} </small></td>
                    </tr>
                  </tbody>
                    </table>
                    <table class="table table-sm text-nowrap table-hover">
                      <thead class="thead-dark" v-if="fastAvaliacoesRel.loading">
                        <tr>
                          <th
                            colspan="12"
                            class="text-center"
                          >
                          <b-icon icon="gear-fill" animation="spin"/> Carregando 
                          </th>
                        </tr>
                      </thead>
                      <thead class="thead-dark" v-if="!fastAvaliacoesRel.loading">
                        <tr>
                          <th class="pl-3 pt-2">
                            <b-form-checkbox v-model="fastAvaliacoesRel.selectAll"/>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastAvaliacoesRel.orderBy.ano_matricula !== 0 }" @click="orderByColumn('ano_matricula')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                              Ano Matr.&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastAvaliacoesRel.orderBy.ano_matricula === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastAvaliacoesRel.orderBy.ano_matricula === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastAvaliacoesRel.orderBy.data_matricula !== 0 }" @click="orderByColumn('data_matricula')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                              Data Matr.&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastAvaliacoesRel.orderBy.data_matricula === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastAvaliacoesRel.orderBy.data_matricula === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastAvaliacoesRel.orderBy.data_realizacao !== 0 }" @click="orderByColumn('data_realizacao')" role="button" class="font-weight-bold d-flex pb-1 justify-content-center">
                             Data Realização&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastAvaliacoesRel.orderBy.data_realizacao === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastAvaliacoesRel.orderBy.data_realizacao === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastAvaliacoesRel.orderBy.aluno !== 0 }" @click="orderByColumn('aluno')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                              Aluno&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastAvaliacoesRel.orderBy.aluno === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastAvaliacoesRel.orderBy.aluno === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastAvaliacoesRel.orderBy.nota !== 0 }" @click="orderByColumn('nota')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                              Nota&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastAvaliacoesRel.orderBy.nota === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastAvaliacoesRel.orderBy.nota === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastAvaliacoesRel.orderBy.resultado !== 0 }" @click="orderByColumn('resultado')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                              Resultado&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastAvaliacoesRel.orderBy.resultado === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastAvaliacoesRel.orderBy.resultado === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small class="font-weight-bold d-flex pb-1 justify-content-center">Gabarito</small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastAvaliacoesRel.orderBy.idade !== 0 }" @click="orderByColumn('idade')" role="button" class="font-weight-bold d-flex pb-1 justify-content-center">
                             Idade&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastAvaliacoesRel.orderBy.idade === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastAvaliacoesRel.orderBy.idade === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastAvaliacoesRel.orderBy.matricula !== 0 }" @click="orderByColumn('matricula')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                              Matrícula&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastAvaliacoesRel.orderBy.matricula === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastAvaliacoesRel.orderBy.matricula === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastAvaliacoesRel.orderBy.valor_pago !== 0 }" @click="orderByColumn('valor_pago')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                              Valor Pago&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastAvaliacoesRel.orderBy.valor_pago === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastAvaliacoesRel.orderBy.valor_pago === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastAvaliacoesRel.orderBy.valor_em_aberto !== 0 }" @click="orderByColumn('valor_em_aberto')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                              Valor em Aberto&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastAvaliacoesRel.orderBy.valor_em_aberto === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastAvaliacoesRel.orderBy.valor_em_aberto === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastAvaliacoesRel.orderBy.avaliacao !== 0 }" @click="orderByColumn('avaliacao')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                              Avaliação&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastAvaliacoesRel.orderBy.avaliacao === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastAvaliacoesRel.orderBy.avaliacao === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastAvaliacoesRel.orderBy.turma !== 0 }" @click="orderByColumn('turma')" role="button" class="font-weight-bold d-flex pb-1 justify-content-center">
                              Turma&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastAvaliacoesRel.orderBy.turma === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastAvaliacoesRel.orderBy.turma === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastAvaliacoesRel.orderBy.curso !== 0 }" @click="orderByColumn('curso')" role="button" class="font-weight-bold d-flex pb-1 justify-content-center">
                              Curso&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastAvaliacoesRel.orderBy.curso === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastAvaliacoesRel.orderBy.curso === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="fastAvaliacoesRel.loading">
                    <tr>
                      <td
                        colspan="12"
                        class="text-center"
                      >
                      <b-icon icon="gear-fill" animation="spin"/> Carregando 
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else-if="fastAvaliacoesRel.paginacao.length">
                    <tr
                      v-for="(e, index) in fastAvaliacoesRel.paginacao"
                      :key="index"
                    >
                      <td class="pl-3 pt-2"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                      >
                        <b-form-checkbox role="button" type="checkbox" v-model="fastAvaliacoesRel.selectedItems" :value="e.id_matricula + '-' + e.id"/>
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                        
                      >
                        <small>{{ e.ano_matricula }}</small>                                  
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                        
                      >
                        <small>{{ formataDataT(e.data_matricula) }}</small>                                  
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                          
                      >
                          <small v-if="e.DataRealizacaoFim">{{ e.DataRealizacaoFim }}</small>
                          <small v-else>Sem data</small>                               
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                        
                      >
                        <small>{{ e.NomeAluno }}</small>                                  
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                        
                      >
                        <small v-if="e.Nota">{{ e.Nota }}</small>             
                        <small v-else>-</small>                     
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                        
                      >
                        <small v-if="e.Resultado === 'Aprovado'" class="text-success">{{ e.Resultado }}</small>
                        <small v-else-if="e.Resultado === 'Reprovado'" class="text-danger">{{ e.Resultado }}</small>          
                        <small v-else>-</small>                     
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                      >
                        <a v-if="e.Gabarito" role="button" title="GABARITO" :href="e.Gabarito" target="_blank" class="text-primary"><u>Ver</u></a>
                        <span v-else>-</span>
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                        
                      >
                        <small>{{ e.Idade }}</small>                                  
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                        
                      >
                        <small>{{ e.id_matricula }}</small>                                  
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                        
                      > 
                        <small v-if="e.ValorPago">R$ {{ formataPreco(e.ValorPago) }}</small> 
                        <small v-else>R$ 0,00</small>                                  
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                        
                      >
                        <small v-if="e.ValorEmAberto">R$ {{ formataPreco(e.ValorEmAberto) }}</small> 
                        <small v-else>R$ 0,00</small>                                   
                      </td> 
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                          
                      >
                          <small>{{ e.Avaliacao }}</small>                               
                      </td>  
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                          
                      >
                          <small>{{ e.Turma }}</small>                               
                      </td>            
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                          
                      >
                          <small>{{ e.Curso }}</small>                               
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td
                        colspan="12"
                        class="text-center w-100"
                      >
                        Nenhuma avaliação encontrada
                      </td>
                    </tr>
                  </tbody>
                    </table>
                  </div>
                  <div class="col-12 text-right mt-4">
                      <div class="d-inline">
                        <h6 class="font-weight-bold d-inline">Valor Total em Aberto: </h6>
                        <h5 class="d-inline"><b-badge variant="primary">R$ {{ formataPreco(totalEmAberto) }}</b-badge></h5>
                      </div>
                      <div class="mt-2 mb-2">
                        <h6 class="font-weight-bold d-inline">Valor Total Pago: </h6>
                        <h5 class="d-inline"><b-badge variant="success">R$ {{ formataPreco(totalPago) }}</b-badge></h5>
                      </div>
                  </div>
                  
                  <div class="col-12 text-center mt-4">
                <Pagination
                  :page-size="20"
                  :items="fastAvaliacoesRel.filtro"
                  @changePage="fastAvaliacoesRel.paginacao = $event"
                />
              </div>
                </div>
            </div>
          </transition>
          <!-- /Conteúdo Principal Relatório -->

          <div class="mt-5 mb-5">
            <a
              v-if="fastNavegacao.exibirRelatorio" 
              id="btn_voltar_modo_admin"
              class="btn btn-red-hollow"
              :href="
                '/plataforma/' + $route.params.id_plataforma + '/secretaria' 
              "
              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
            >
              <b-icon-arrow-return-left />
              Voltar
            </a>
          </div>

        </div>

        <!--modals-->
        <modal
          name="modalVerLog"
          :scrollable="true"
          height="auto"
          :shift-y="0.3"
          :focus-trap="true"
          :adaptive="true"
        >
          <div class="row p-4">
            <div class="col-10 pl-4 pr-4">
              <h4>Log / Motivo</h4>
            </div>
            <div class="col-2 pl-4 pr-4 text-right">
              <a
                class="btn btn-secondary"
                href="#"
                @click.prevent="hideModal('modalVerLog')"
              >
                <b-icon-arrow-return-left />
              </a>
            </div>
            <div class="col-12 p-4">
              <div class="row">
                <div class="col-12 mt-4 mb-4">

                  <p class="text-info">
                    {{ fastAvaliacoesRel.logRecebivel }}
                  </p>

                </div>
              </div>
              <div class="row mt-4">
                <div class="col-12">
                  <button class="btn btn-secondary mr-2" @click="hideModal('modalVerLog'); fastLinkClipboard = false">
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </modal>

      </div>
    </div>
  </template>
  
  <script>
  import { settings } from "@/config";
  import methods from "@/methods";
  import Pagination from "../components/Pagination";
  import DetalhesRecebivel from "../components/DetalhesRecebivel.vue"
  import * as XLSX from 'xlsx';
  
  export default {
    name: "HomeInternoTesourariaRelatoriosMatriculas",
    components: {
      Pagination,
      DetalhesRecebivel
    },
    mixins: [methods],
    data: function () {
      return {
        modalWidthSm: this.isMobile() ? "80%" : "30%",
        modalWidthMd: this.isMobile() ? "90%" : "50%",
        modalWidthLg: this.isMobile() ? "100%" : "90%",
        
        fastLinkClipboard: false,
        fastNavegacao: {
          exibirRelatorio : true,
        },
        fastAvaliacoesRel: {
          loading: true,
          filtro: [],
          paginacao: [],
          filtroEscolhido : {
            idPlataforma: this.$route.params.id_plataforma,
            idTurma: 0,
            idCurso: 0,
            nomeAluno: '',
            matriculaAluno: 0,
            anoMatricula: 0,
            dataMatriculaStart: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
            dataMatriculaEnd: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
            dataRealizacaoStart: null,
            dataRealizacaoEnd: null,
            statusAprovado: '',
          },
          filtros : {
            plataformas: [],
            status: '',
            turmas: [],
            cursos: [],
            anos: [
              new Date().getFullYear() - 3,
              new Date().getFullYear() - 2,
              new Date().getFullYear() - 1,
              new Date().getFullYear(),
              new Date().getFullYear() + 1,
              new Date().getFullYear() + 2,
              new Date().getFullYear() + 3
            ],
            datalistId: 'suggestions-list-' + Math.floor(Math.random() * 1000000),
          },
          logRecebivel: '',
          gatewaysPagamento: [],
          orderBy: {
            curso: 0,
            data_nascimento: 0,
            data_realizacao: 0,
            idade: 0,
            aluno: 0,
            nota: 0,
            resultado: 0,
            valor_em_aberto: 0,
            valor_pago: 0,
            ano_matricula: 0,
            data_matricula: 0,
            matricula: 0,
          },
          selectedItems: [],
          selectAll: false
        },
        fastInvoiceFiltrosDetalhes: {
          idPlataforma : '',
          idInvoice : '',
          parcela : '',
        },
        tableData : [],
      };
    },
    mounted: function () {
      if (this.$route.params.id_plataforma) {
        this.getFastDependecias(this.$route.params.id_plataforma, 'tesouraria')
          .then(() => {
            if (
              this.$store.state.fastPlataformaModulos.modulo_secretaria
            ) {            
              this.$store.state.fastCarregando = false;
              this.getFastHoraServidor()
              .then(() => {
                this.getPlataformasFilhas()
                this.getAvaliacoesRel(this.$route.params.id_plataforma)
                this.getTurmas()
                this.getCursos()
              })
  
            } else {
              this.$store.state.fastCarregando = false;
              this.exibeToasty("Módulo não disponível para sua plataforma", "error");
            }
          })
          .catch((e) => {
            this.exibeToasty(e, "error");
            this.$store.state.fastCarregando = false;
          });
      }
    },
    computed: {
      totalEmAberto() {
        return this.fastAvaliacoesRel.filtro.reduce((total, item) => {
          if (!this.fastAvaliacoesRel.selectedItems.length){
            return total + item.ValorEmAberto
          }
          if (this.fastAvaliacoesRel.selectedItems.includes(item.id_matricula + "-" + item.id)) {
            return total + item.ValorEmAberto
          } else {
            return total
          }
        }, 0)
      },
      totalPago() {
        return this.fastAvaliacoesRel.filtro.reduce((total, item) => {
          if (!this.fastAvaliacoesRel.selectedItems.length){
            return total + item.ValorPago
          }
          if (this.fastAvaliacoesRel.selectedItems.includes(item.id_matricula + "-" + item.id)) {
            return total + item.ValorPago
          } else {
            return total
          }
        }, 0)
      },
    },
    watch: {
      'fastAvaliacoesRel.selectAll'(val) {
        if (val) {
          this.fastAvaliacoesRel.selectedItems = this.fastAvaliacoesRel.filtro.map((item) => item.id_matricula + "-" + item.id)
        } else {
          this.fastAvaliacoesRel.selectedItems = []
        }
      }
    },
    methods: {
      getPlataformasFilhas(){
      //Pega plataformas filhas para o filtro
      this.promiseGetFastApi("api/fast_plataforma/lista_filhas_novo", `id_plataforma=${this.fastAvaliacoesRel.filtroEscolhido.idPlataforma}`)
      .then((res) => {          
        if (res.Data.length) {
          this.fastAvaliacoesRel.filtros.plataformas = res.Data;
        } else{
          this.fastAvaliacoesRel.filtros.plataformas = this.$route.params.id_plataforma;
        }
      })
      .catch((e) => {
          console.log(e);
      });
      },
      getTurmas(){
        //Pega turmas para o filtro
        this.promiseGetFastApi("api/fast_secretaria_turma/lista", `id_plataforma=${this.fastAvaliacoesRel.filtroEscolhido.idPlataforma}&id_turma=0`)
        .then((res) => {          
          if (res.length) {
            this.fastAvaliacoesRel.filtros.turmas = res;
          }else{
            this.fastAvaliacoesRel.filtros.turmas = [];
          }
        })
        .catch((e) => {
            console.log(e);
        });
      },
      getCursos(){
        //Pega cursos para o filtro
        this.promiseGetFastApi("api/fast_secretaria_curso/lista", `id_plataforma=${this.fastAvaliacoesRel.filtroEscolhido.idPlataforma}&id_secretaria_curso=0`)
        .then((res) => {          
          if (res.length) {
            this.fastAvaliacoesRel.filtros.cursos = res;
          }else{
            this.fastAvaliacoesRel.filtros.cursos = [];
          }
        })
        .catch((e) => {
            console.log(e);
        });
      },
      async getAvaliacoesRel(){       
        this.fastAvaliacoesRel.loading = true

        let plataforma;
        let todasPlataformasFilhas;

        todasPlataformasFilhas = this.fastAvaliacoesRel.filtroEscolhido.idPlataforma === 'Todas';
        plataforma = todasPlataformasFilhas ? this.$route.params.id_plataforma : this.fastAvaliacoesRel.filtroEscolhido.idPlataforma;

        this.promiseGetFastApi("api/fast_secretaria_avaliacao_aluno/lista_avaliacoes", `IdPlataforma=${plataforma ? plataforma : 0}&IdTurma=${this.fastAvaliacoesRel.filtroEscolhido.idTurma ? this.fastAvaliacoesRel.filtroEscolhido.idTurma : 0}&IdCurso=${this.fastAvaliacoesRel.filtroEscolhido.idCurso ? this.fastAvaliacoesRel.filtroEscolhido.idCurso : 0}&NomeAluno=${this.fastAvaliacoesRel.filtroEscolhido.nomeAluno}&MatriculaAluno=${this.fastAvaliacoesRel.filtroEscolhido.matriculaAluno ? this.fastAvaliacoesRel.filtroEscolhido.matriculaAluno : 0}&AnoMatricula=${this.fastAvaliacoesRel.filtroEscolhido.anoMatricula ? this.fastAvaliacoesRel.filtroEscolhido.anoMatricula : ''}&DataMatriculaStart=${this.fastAvaliacoesRel.filtroEscolhido.dataMatriculaStart ? this.fastAvaliacoesRel.filtroEscolhido.dataMatriculaStart : ''}&DataMatriculaEnd=${this.fastAvaliacoesRel.filtroEscolhido.dataMatriculaEnd ? this.fastAvaliacoesRel.filtroEscolhido.dataMatriculaEnd : ''}&DataRealizacaoStart=${this.fastAvaliacoesRel.filtroEscolhido.dataRealizacaoStart ? this.fastAvaliacoesRel.filtroEscolhido.dataRealizacaoStart : ''}&DataRealizacaoEnd=${this.fastAvaliacoesRel.filtroEscolhido.dataRealizacaoEnd ? this.fastAvaliacoesRel.filtroEscolhido.dataRealizacaoEnd : ''}&Aprovado=${this.fastAvaliacoesRel.filtroEscolhido.statusAprovado}`)

        .then(res => {

          if (res.length) {

            //id para usar no select checkbox da tabela
            const listaAvaliacoes = res.map((item, index) => {
                return { ...item, id: index };
            });

            console.log("getAvaliacoesRel", listaAvaliacoes)

            this.fastAvaliacoesRel.filtro = todasPlataformasFilhas ? listaAvaliacoes : listaAvaliacoes.filter(item => item.id_plataforma == this.fastAvaliacoesRel.filtroEscolhido.idPlataforma);

            console.log('length teste', this.fastAvaliacoesRel.filtro.length)
          }
          else {
            this.fastAvaliacoesRel.filtro = []
          }
  
          this.fastAvaliacoesRel.loading = false
        }).catch(e => {
          console.log(e)
          this.exibeToasty(e, "error")
          this.fastAvaliacoesRel.loading = false
        })
      },
      limpaFiltros(){
        this.fastAvaliacoesRel.filtroEscolhido.idPlataforma = this.$route.params.id_plataforma
        this.fastAvaliacoesRel.filtroEscolhido.dataMatriculaStart = new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10)
        this.fastAvaliacoesRel.filtroEscolhido.dataMatriculaEnd = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10)
        this.fastAvaliacoesRel.filtroEscolhido.dataRealizacaoStart = null
        this.fastAvaliacoesRel.filtroEscolhido.dataRealizacaoEnd = null
        this.fastAvaliacoesRel.filtroEscolhido.anoMatricula = 0
        this.fastAvaliacoesRel.filtroEscolhido.matriculaAluno = 0
        this.fastAvaliacoesRel.filtroEscolhido.nomeAluno = ''
        this.fastAvaliacoesRel.filtroEscolhido.idTurma = 0
        this.fastAvaliacoesRel.filtroEscolhido.idCurso = 0
        this.fastAvaliacoesRel.filtroEscolhido.statusAprovado = ''

        this.getAvaliacoesRel();
      },
      exportaPlanilha(){
        const table = document.querySelector('#relatorio');
        const tableRows = table.querySelectorAll('tr');
        const tableData = [];

        for (let i = 0; i < tableRows.length; i++) {
          const tableCells = tableRows[i].querySelectorAll('td');
          const rowData = [];

          for (let j = 0; j < tableCells.length; j++) {
            rowData.push(tableCells[j].innerText);
          }

          tableData.push(rowData);
        }

        tableData[0] = ['Ano Matrícula', 'Data Matrícula', 'Data Realização', 'Aluno', 'Nota', 'Resultado', 'Gabarito', 'Idade', 'Matrícula', 'Valor Pago', 'Valor em ABerto', 'Turma', 'Curso'];

        const worksheet = XLSX.utils.aoa_to_sheet(tableData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'FastEAD - relatorio-avaliações.xlsx');
      },
      copyURLBoleto(link) {
      const el = document.createElement('textarea');  
      el.value = link;                                 
      el.setAttribute('readonly', '');                
      el.style.position = 'absolute';                     
      el.style.left = '-9999px';                      
      document.body.appendChild(el);                  
      const selected =  document.getSelection().rangeCount > 0  ? document.getSelection().getRangeAt(0) : false;                                    
      el.select();                                    
      document.execCommand('copy');                   
      document.body.removeChild(el);                  
      if (selected) {                                 
        document.getSelection().removeAllRanges();    
        document.getSelection().addRange(selected);   
      }
      },
      orderByColumn(coluna) {
        Object.keys(this.fastAvaliacoesRel.orderBy).forEach(key => {
          if (key !== coluna) {
            this.fastAvaliacoesRel.orderBy[key] = 0;
          }
        });
        // Atualiza a ordem de classificação para a coluna clicada
        this.fastAvaliacoesRel.orderBy[coluna] = this.fastAvaliacoesRel.orderBy[coluna] === 1 ? -1 : 1;

        // Classifica a tabela de acordo com as colunas ordenadas e a ordem de classificação
        this.fastAvaliacoesRel.filtro.sort((a, b) => {
          const order = this.fastAvaliacoesRel.orderBy[coluna];
          
          if (coluna === 'curso') {
            return a.Curso.localeCompare(b.Curso) * order;
          } else if(coluna === 'turma'){
            return a.Turma.localeCompare(b.Turma) * order;
          } else if(coluna === 'avaliacao'){
            return a.Avaliacao.localeCompare(b.Avaliacao) * order;
          } else if (coluna === 'data_realizacao') {
            function ordenarPorData(a, b, order) {
              // Verifica se ambos os valores são nulos ou iguais a "Sem data"
              if ((!a || a === 'Sem data') && (!b || b === 'Sem data')) {
                return 0;
              }
              // Verifica se apenas um dos valores é nulo ou "Sem data" e coloca por último
              if (!a || a === 'Sem data') {
                return 1 * order;
              }
              if (!b || b === 'Sem data') {
                return -1 * order;
              }
              // Ordena as datas restantes normalmente
              const dataA = new Date(a);
              const dataB = new Date(b);
              return (dataA > dataB ? 1 : -1) * order;
            }
            return ordenarPorData(a.DataRealizacaoFim, b.DataRealizacaoFim, order);
          } else if (coluna === 'idade') {
            return (a.Idade > b.Idade ? 1 : -1) * order; 
          } else if (coluna === 'aluno') {
            return a.NomeAluno.localeCompare(b.NomeAluno) * order;
          } else if (coluna === 'nota') {
            return (a.Nota > b.Nota ? 1 : -1) * order; 
          } else if (coluna === 'resultado') {
            return a.Resultado.localeCompare(b.Resultado) * order;
          } else if (coluna === 'valor_em_aberto') {
            return (a.ValorEmAberto - b.ValorEmAberto) * order;
          } else if (coluna === 'valor_pago') {
            return (a.ValorPago - b.ValorPago) * order;
          } else if (coluna === 'ano_matricula') {
            return (a.ano_matricula - b.ano_matricula) * order;
          } else if (coluna === 'data_matricula') {
            return a.data_matricula.localeCompare(b.data_matricula) * order;
          } else if (coluna === 'matricula') {
            return (a.id_matricula - b.id_matricula) * order;
          } else {
              return 0;
          }
        });
      },
      validaDataFiltro(inicio, fim, infErro){
        if(inicio === '' || fim === ''){
          return true
        } else if(inicio > fim){
          if (infErro) this.exibeToasty('A data inicial não pode ser maior que a data final', 'error');
          return false
        }
        return true;
      },
      criaTooltip(e){

        return `<div class="text-left">
                  Aluno: ${e.NomeAluno}<br>
                  Result.: ${e.Resultado}<br>
                  Data Matrícula: ${this.formataDataT(e.data_matricula)}<br>
                  Valor em Aberto: R$ ${e.ValorEmAberto ? this.formataPreco(e.ValorEmAberto) : this.formataPreco(0)}<br>
                  Valor Pago: R$ ${e.ValorPago ? this.formataPreco(e.ValorPago) : this.formataPreco(0)}
                </div>`;
      }
    },
  };
  </script>
  
  <style scope>
  .container{
    max-width: 1280px;
  }
  .sugestao-lista {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    border: 1px solid #d8d8d8;
    border-top: none;
    padding: 0;
    margin: 0;
    z-index: 999;
  }
  .sugestao-item {
    list-style-type: none;
    padding: 3px 6px;
    cursor: pointer;
    color: rgb(95, 95, 95);
  }
  .sugestao-item:hover {
    transition: 0.3s;
    background-color: #e8e8e8;
  }
  .date-picker-filtro  {
    text-align: center;
  }
  .btn-custom-primary{
    background-color: #007bff!important;
    color: #fff!important;
  }
  .btn-custom-outline-primary{
    background-color: #fff!important;
    color: #007bff!important;
    border:1px solid #007bff!important;
  }

  </style>
  